import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers['access_token'] = localStorage.getItem("user_token");
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://shopapi.hbcygc.net/api/v1/portal/',
  // 超时
  timeout: 1000 * 60 * 3
});

service.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers['access_token'] = localStorage.getItem("user_token");
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = res.data.message;
  if (res.data.code == 288) {
    //return Promise.reject('token过期');
    return new Promise((resolve,reject)=>{
      console.log('token过期');
      location.href = '#/login';
    }).catch((e)=>{console.log(e)});
  }
  return res.data;
}, error => {

});



export default service
<template>
  <div id="app">
    <nav :class="{ 'nav-down': scrollTop > 50 }">
      <div class="top-menu">
        <div>
          <router-link to="/" target="_blank"><img v-if="scrollTop > 50"
              src="https://img.hbcygc.net/website/v2/companys/logo2.png"></router-link>
          <router-link to="/" target="_blank"><img v-if="scrollTop <= 50"
              src="https://img.hbcygc.net/website/v2/companys/logo1.png"></router-link>
        </div>
        <div class="top-menu-a">
          <router-link to="/crosser" target="_blank">穿越号</router-link>
          <router-link to="/screen" target="_blank">巨幕号</router-link>
          <router-link to="/future" target="_blank">未来号</router-link>
          <router-link to="/fos" target="_blank">futureOS</router-link>
          <router-link to="/company" target="_blank">关于我们</router-link>
          <router-link to="/info" target="_blank" class="f-info">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" class="bi bi-person">
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z">
              </path>
            </svg>
          </router-link>
        </div>
      </div>

    </nav>
    <router-view />
    <footer>
      <div class="f-top">
        <div class="f-top-left">
          <div class="f-top-left-item">
            <p>只见未来</p>
            <router-link to="/crosser" target="_blank">穿越号</router-link>
            <router-link to="/screen" target="_blank">巨幕号</router-link>
            <router-link to="/future" target="_blank">未来号</router-link>
          </div>
          <div class="f-top-left-item">
            <p>未来全屏通</p>
            <router-link to="/fos" target="_blank">FOS介绍</router-link>
            <router-link to="/ar" target="_blank">穿越者</router-link>
            <router-link to="/creator" target="_blank">future开发者联盟</router-link>
          </div>
          <div class="f-top-left-item">
            <p>关于我们</p>
            <router-link to="/meta" target="_blank">未来元宇宙</router-link>
            <router-link to="/company" target="_blank">公司简介</router-link>
            <router-link to="/about" target="_blank">联系我们</router-link>
            <router-link to="/consult" target="_blank">立即咨询</router-link>
          </div>
        </div>
        <div class="f-top-right">
          <img class="future-img" src="./assets/images/future.png" />
          <p class="p2">未来全屏通智能科技有限公司</p>
          <p class="p3">服务邮箱</p>
          <p class="p4">service@fxreye.com</p>
        </div>
      </div>
      <div class="f-down">
        <p>武汉东湖新技术开发区雄庄路8号App广场2号楼10层</p>
        <p>
          Copyright 2020-2023 Future Fullscreen Intelligent Technology Group Co., Ltd.
          鄂ICP备鄂ICP备2023002274号-1  <img src="./assets/images/police-badge.png">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=42018502007119" rel="noreferrer"
            target="_blank">鄂公网安备42018502007119</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<style lang="less">
@font-face {
  font-family: "Righteous-Regular";
  src: url('./font-familys/Righteous-Regular.ttf')
}

@font-face {
  font-family: "pangmenzhengdao";
  src: url('./font-familys/pangmenzhengdao.ttf')
}

/* 宽度 */
::-webkit-scrollbar {
  width: 6px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}


.trunk {
  min-height: 100vh;
  // background-color: #888;
}

nav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;

  .top-menu {
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      // width: auto;
      // height: 28px;
    }

    .top-menu-a {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 30px;

      a {
        color: #fff;
        opacity: 0.6;
        cursor: pointer;
        transition: 0.5s;
        text-decoration: none;

        // &.router-link-exact-active {
        //   color: #fff;
        // }
      }

      a:hover {
        color: #fff;
        opacity: 1;
      }

      .f-info {

        // border: 1px solid #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.nav-down {
  background-color: #fff;

  .top-menu {
    .top-menu-a {

      a {
        color: #111111;
      }

      a:hover {
        color: #111111;
        opacity: 1;
      }
    }
  }

  a {
    color: black;
  }
}

footer {
  width: 100%;
  height: 800px;
  background-color: #05080C;
  position: relative;
  z-index: 95;

  .f-top {
    // background-color: cadetblue;
    width: 80vw;
    height: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #76787a;
    // padding-right: 50px;

    .f-top-left {
      width: 850px;
      height: 400px;
      // background-color: antiquewhite;
      display: flex;
      justify-content: left;
      // align-items: center;
      column-gap: 60px;
      padding-top: 150px;

      .f-top-left-item {
        width: 130px;
        height: 200px;
        // background-color: salmon;

        p {
          color: #fff;
          font-weight: bold;
          margin-bottom: 30px;
        }

        a {
          color: #76787a;
          text-decoration: none;
          width: 100%;
          display: block;
          font-size: 14px;
          margin-bottom: 15px;
        }

        a:hover {
          color: #fff;
        }
      }
    }

    .f-top-right {
      width: 265px;
      height: 600px;
      // background-color: black;
      padding-top: 150px;
      padding-left: 30px;
      color: #a4a5a6;

      .future-img {
        margin-bottom: 25px;
        width: 138px;
        height: auto;
      }

      .p2 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 80px;
      }

      .p3 {
        font-size: 15px;
        // font-weight: bold;
        margin-bottom: 0;
      }

      .p4 {
        font-size: 24px;
        font-family: 'pangmenzhengdao';
      }
    }
  }

  .f-down {
    // background-color: saddlebrown;
    width: 80vw;
    height: 200px;
    margin: 0 auto;
    color: #76787a;
    padding-top: 20px;
    font-size: 14px;

    img {
      width: 14px;
      height: auto;
      margin: 0 5px;
    }

    a {
      color: #76787a;
      text-decoration: none;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      scrollTop: 0
    }
  },
  created() {
    if (this.$isMobile()) {
      window.location.href = 'https://m.future.top/';
    } else {
      // window.location.href = 'http://106.14.199.94:8066/';
    }
  },
  mounted() {
    window.addEventListener("scroll", (e) => { this.ergodic(); });
  },
  methods: {
    ergodic: function () {
      this.scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
  }
}
</script>

<template>
  <div class="trunk">
    <div class="lb">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide>
          <div class="lb-panel"
            :style="{ backgroundImage: 'url(https://img.hbcygc.net/website/v2/homes/carousel1.png)' }">
            <div class="font-panel">
              <p class="p1">轻装出行时尚，精彩跃然镜上</p>
              <p class="p2"><span>M.vision</span>穿越号</p>
              <div class="btns">
                <router-link to="/preorder?productId=8e8ae792-0460-11ef-ad74-00163e2ab162" target="_blank">
                  <span>立即预约</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </router-link>
                <router-link to="/crosser" target="_blank">
                  <span>了解更多</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></router-link>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="lb-panel"
          :style="{ backgroundImage: 'url(https://img.hbcygc.net/website/v2/homes/carousel2.png)' }">
            <div class="font-panel">
              <p class="p1" style="color: #D2D7DF;">轻装出行时尚，精彩跃然镜上</p>
              <p class="p2"><span>M.vision</span>穿越号</p>
              <div class="btns">
                <router-link to="/preorder?productId=8e8ae792-0460-11ef-ad74-00163e2ab162" target="_blank">
                  <span>立即预约</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </router-link>
                <router-link to="/crosser" target="_blank">
                  <span>了解更多</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></router-link>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="hxys">
      <div class="hxys-top">
        <div>核心优势</div>
        <div class="btns">
          <a href="javascript:;" @click="hxys_prev">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
          </a>
          <a href="javascript:;" @click="hxys_next">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </a>
        </div>
      </div>
      <div class="hxys-list">
        <div class="hxys-panel">
          <swiper :options="swiperOption2" class="swiper-container-list2" ref="mySwiper">
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys1.png">
                <div class="font-panel">
                  <p class="p1">光学</p>
                  <p class="p2">行业领先光学，精准定位， </p>
                  <p class="p2">清晰呈现，让视觉更智能。</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys2.png">
                <div class="font-panel">
                  <p class="p1">CR模式</p>
                  <p class="p2">穿越现实(Crossing Reality)，通过合理分割用户的视</p>
                  <p class="p2">野，实现虚拟世界和真实世界在同一空间中共存。</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys3.png">
                <div class="font-panel">
                  <p class="p1">整机</p>
                  <p class="p2">高效交互，舒适佩戴，</p>
                  <p class="p2">整机带来前所未有的便捷体验。</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys4.png">
                <div class="font-panel">
                  <p class="p1">团队</p>
                  <p class="p2">深厚技术底蕴，创新思维引领，</p>
                  <p class="p2">团队领航行业前沿。</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys5.png">
                <div class="font-panel">
                  <p class="p1">供应链</p>
                  <p class="p2">整合全球优质资源，稳定高效，</p>
                  <p class="p2">确保产品持续创新迭代。</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys6.png">
                <div class="font-panel">
                  <p class="p1">生态</p>
                  <p class="p2">开放合作，共赢共生，</p>
                  <p class="p2">激发无限创新可能，实现价值共创与共享。</p>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
    <div class="video-panel">
      <video ref="video" class="video-js vjs-default-skin" muted="muted" controls>
        <source src="https://img.hbcygc.net/website/pc/index/topvideo.mp4" />
      </video>
    </div>
    <div class="download-panel">
      <img src="https://img.hbcygc.net/website/v2/homes/scancode.png">
      <div class="item-panel">
        <div class="download-item">
          <p class="p1">穿越者app</p>
          <img class="rounded" :src="qrCodeImg">
          <p class="p2">扫码下载</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.swiper-container-list2 {
  overflow: visible;
}

.swiper-pagination-bullet {
  width: 35px;
  height: 4px;
  border-radius: 0;
  background-color: #fff;
  opacity: 0.3;
}

.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
</style>
<style scoped lang="less">
.lb {
  .lb-panel {
    width: 100vw;
    height: 100vh;
    text-align: center;
    color: #fff;
    padding-top: 120px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    p {
      margin: 0;
    }

    .p1 {
      font-size: 24px;
      color: #8E8E8E;
    }

    .p2 {
      font-size: 72px;
      font-weight: bold;

      span {
        font-family: 'Righteous-Regular';
        font-weight: 200;
        margin-right: 20px;
      }
    }

    .btns {
      // border: 1px solid red;
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 25px;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      border-radius: 50px;
      border: 1px solid #fff;
      font-size: 16px;
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    a:hover {
      background-color: #fff;
      color: #000000;
    }
  }
}

.hxys {
  width: 100%;
  height: 600px;
  // background-color: cadetblue;

  .hxys-top {
    font-size: 56px;
    // background-color: aquamarine;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    height: 150px;
    margin: auto;

    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 30px;

      a {
        color: #9E9E9E;
        background-color: #F5F5F5;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: all 0.5s;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      a:hover {
        width: 50px;
        height: 50px;
        color: #fff;
        background-color: #000000;
      }
    }
  }

  .hxys-list {
    width: 100%;
    height: 420px;
    // background-color: blueviolet;
    position: relative;
    overflow-x: hidden;

    .hxys-panel {
      max-width: 1200px;
      margin: 0 auto;

      .hxys-item-panel {
        width: 400px;
        height: 420px;
        background-color: #F4F6F9;
        overflow: hidden;
        position: relative;
        z-index: 0;

        img {
          width: 100%;
          height: auto;
          transition: 1s;
          position: relative;
          z-index: 1;
        }

        img:hover {
          transform: scale(1.2);
        }

        .font-panel {
          position: absolute;
          z-index: 2;
          left: 30px;
          bottom: 15px;
          color: #fff;
          // border: 1px solid red;

          .p1 {
            font-size: 24px;
            margin-bottom: 20px;
          }

          .p2 {
            font-size: 14px;
            margin-bottom: 5px;
            color: #AFAFAF;
          }
        }

        // .font-panel{
        //   background-color: salmon;
        //   opacity: 0.8;
        //   position: absolute;
        //   z-index: 2;
        //   width: 100%;
        //   height: 100%;
        //   left: 0;
        //   top: 0;
        // }

        // .hxys-item-panel-img {
        //   // width: 100%;
        //   overflow: hidden;
        //   /* 隐藏超出容器的部分 */

        //   img {
        //     width: 100%;
        //     height: auto;
        //     transition: 1s;
        //   }

        //   img:hover {
        //     transform: scale(1.5);
        //     /* 保持图片尺寸不变 */
        //   }
        // }

        // .hxys-item-panel-content {
        //   // background-color: aqua;
        //   // width: 100%;
        //   // height: 100px;
        //   padding: 28px;

        //   p {
        //     font-size: 24px;
        //     font-weight: 400;
        //     margin-bottom: 5px;
        //   }

        //   span {
        //     font-size: 16px;
        //     font-weight: 400;
        //   }
        // }
      }
    }
  }
}

.video-panel {
  background-color: #000000;
  width: 100%;
  height: 750px;
  // background-color: salmon;
  display: flex;
  justify-content: center;
  align-items: center;

  .video-js {
    width: 1200px;
    height: 675px;
  }
}

.download-panel {
  position: relative;
  z-index: 0;

  img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  .item-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    .download-item {
      text-align: center;
      width: 400px;
      height: 400px;

      .p1 {
        font-size: 36px;
      }

      .p2 {
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 400;
        margin-top: 20px;
        color: #AFAFAF;
      }

      img {
        width: 180px;
        height: auto;
        margin-top: 30px;
      }
    }
  }


}
</style>
<script>

import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { getQrCode } from '../utils/api';

export default {
  name: 'HomeSwiper',
  components: {
    swiper,
    swiperSlide
  },

  data() {
    return {
      swiperOption: {
        loop: true,//可选选项，开启循环
        autoplay: true,//可选选项，自动滑动
        pagination: {
          el: '.swiper-pagination',
          // bulletActiveClass: 'my-bullet-active',
        },
        paginationType: 'fraction',
        paginationClickable: true,
      },
      swiperOption2: {
        slidesPerView: 3,
        spaceBetween: 50,
        grabCursor: true,
        paginationClickable: true,
      },
      player: null, // 用来存储当前 video
      videoOpenInti: false,
      qrCodeImg: require('../assets/images/notimg.png'),
      qrCodeUrl: ''
    };
  },
  mounted() {
    this.player = this.$video(this.$refs.video);
    window.addEventListener("scroll", (e) => {
      if ($('.video-js').length > 0 && $('.video-js').get(0).getBoundingClientRect().top < 300) {
        this.openVideo();
      }
    });
  },
  created() {
    this.getCode();
  },
  methods: {
    hxys_prev() {
      console.log(this.$refs.mySwiper.swiper.slidePrev());
    },
    hxys_next() {
      console.log(this.$refs.mySwiper.swiper.slideNext());
    },
    openVideo() {
      if (!this.videoOpenInti) {
        this.videoOpenInti = true;
        this.player.play();
      }

    },
    getCode() {
      let _self = this;
      getQrCode(null).then(
        response => {
          if (response.code == 200) {
            let data = response.data;
            _self.qrCodeImg = data.imgbase;
            _self.qrCodeUrl = data.number;
          }
        }
      ).catch(error => {
        console.log('error:' + error);
      })
    }
  }
}
</script>